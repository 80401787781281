import { application } from "./application";

import { MapkitMap } from "./mapkit_map";
// @ts-ignore
import { Autocomplete } from "stimulus-autocomplete";
import { BarcodeScanner } from "./barcode_scanner";
import { ContactForm } from "./contact_form";
import { ContentPoller } from "./content_poller";
import { Enhancer } from "./enhancer";
import { LinkableNode } from "./linkable_node";
import { Toggle } from "./toggle";

application.register("autocomplete", Autocomplete);
application.register("contact-form", ContactForm);
application.register("content-poller", ContentPoller);
application.register("linkable-node", LinkableNode);
application.register("mapkit-map", MapkitMap);
application.register("toggle", Toggle);
application.register("enhancer", Enhancer);
application.register("barcode-scanner", BarcodeScanner);
