import { toTemporalInstant } from "@js-temporal/polyfill";
import detectLocale from "./detectLocale";
declare global {
  interface Date {
    toTemporalInstant: typeof toTemporalInstant;
  }
}

Date.prototype.toTemporalInstant = toTemporalInstant;

export const updateTimeTags = () => {
  const tzValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeTags = document.querySelectorAll("time");
  for (const time of timeTags) {
    const datetime = time.getAttribute("datetime");
    if (typeof datetime === "string") {
      const timestamp = Date.parse(datetime);
      if (!isNaN(timestamp) && isFinite(timestamp)) {
        const dateObj = new Date();
        dateObj.setTime(timestamp);
        let iso8601String = dateObj.toISOString();
        let iso8601StringWithoutZoneInformation = iso8601String;
        try {
          const temporal = dateObj
            .toTemporalInstant()
            .toZonedDateTimeISO(tzValue);
          iso8601String = temporal.toString();
          iso8601StringWithoutZoneInformation = temporal.toString({
            timeZoneName: "never",
            calendarName: "never",
          });
        } catch (_err) {
          // In some cases, a browser may arrive announcing a timezone that Temporal
          // has no knowledge of ( for example: Etc/Unknown ). In those cases, we
          // just default to JS 8601 string defaults as a "good enough" representation of
          // the time.
        }

        time.setAttribute("title", iso8601String);
        time.setAttribute("datetime", iso8601StringWithoutZoneInformation);
      }
    }
  }
};

export const updateTimestampSpans = () => {
  const spans = document.querySelectorAll("span[data-timestamp]");
  const tzValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const locale = detectLocale();
  for (const span of spans) {
    const timestamp = Number(span.getAttribute("data-timestamp"));
    const optionsJson = span.getAttribute("data-datetime-format-options");
    if (!isNaN(timestamp) && isFinite(timestamp)) {
      const options =
        typeof optionsJson === "string" ? JSON.parse(optionsJson) : undefined;
      const dt = new Date();
      dt.setTime(timestamp);
      span.innerHTML = dt.toLocaleString(locale, options);
      try {
        span.setAttribute(
          "title",
          dt.toTemporalInstant().toZonedDateTimeISO(tzValue).toString()
        );
      } catch (_err) {
        // We default to the ISO8601 default of the string
        // if the browser tries to present a timezone we have
        // no real knowledge of ( like Etc/Unknown )
        span.setAttribute("title", dt.toISOString());
      }
    }
  }
};
