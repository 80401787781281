import { Controller } from "@hotwired/stimulus";

export class ContactForm extends Controller {
  static targets = ["name", "email", "phone", "checkbox"];
  static values = { required: Boolean };
  declare requiredValue: boolean;
  declare readonly nameTarget: HTMLInputElement | null | undefined;
  declare readonly emailTarget: HTMLInputElement | null | undefined;
  declare readonly phoneTarget: HTMLInputElement | null | undefined;
  declare readonly checkboxTarget: HTMLInputElement | null | undefined;
  private submitButton: HTMLInputElement | null | undefined;

  connect() {
    this.nameTarget?.addEventListener("change", () => this.updateForm());
    this.emailTarget?.addEventListener("change", () => this.updateForm());
    this.phoneTarget?.addEventListener("change", () => this.updateForm());
    this.checkboxTarget?.addEventListener("change", () => this.updateForm());
    this.submitButton =
      this.checkboxTarget?.form?.querySelector("input[type=submit]");
    this.updateForm();
  }

  updateForm(): void {
    let valid = !this.requiredValue;
    if (this.checkboxTarget?.checked) {
      const hasName = String(this.nameTarget?.value).trim().length > 0;
      const hasPhone = String(this.phoneTarget?.value).trim().length > 0;
      const email = String(this.emailTarget?.value).trim();
      const hasEmail = email.length > 0 && email.match(/.+@.+/) != null;
      valid = hasName && (hasPhone || hasEmail);
    }
    if (valid) {
      this.submitButton?.removeAttribute("disabled");
    } else {
      this.submitButton?.setAttribute("disabled", "1");
    }
  }
}
