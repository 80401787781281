import jQuery from "jquery";
import Select2Factory from "select2";

function setupAutocompleteInputListener(component: HTMLDivElement) {
  component.addEventListener("autocomplete.change", function (evt: any) {
    const detail = evt.detail as {
      textValue: string;
      value: string;
    } | null;
    if (!detail) {
      return;
    }
    const address = evt.detail.textValue;
    const latLngStr = evt.detail.value;
    const addressInput = component.querySelector<HTMLInputElement>(
      "input[data-autocomplete-address=true]"
    );
    const latitudeInput = component.querySelector<HTMLInputElement>(
      "input[data-autocomplete-latitude=true]"
    );
    const longitudeInput = component.querySelector<HTMLInputElement>(
      "input[data-autocomplete-longitude=true]"
    );
    const latLngComponents = latLngStr.split(",");
    const lat =
      typeof latLngComponents[0] == "string"
        ? parseFloat(latLngComponents[0])
        : null;
    const lng =
      typeof latLngComponents[1] == "string"
        ? parseFloat(latLngComponents[1])
        : null;
    if (addressInput) {
      addressInput.value = address;
    }
    if (latitudeInput) {
      latitudeInput.value = lat?.toString() ?? "";
    }
    if (longitudeInput) {
      longitudeInput.value = lng?.toString() ?? "";
    }
  });
}

function setupAutocompleteAffectsMapListener(component: HTMLDivElement) {
  const mapId = component.getAttribute("data-autocomplete-map-id");
  if (!mapId) {
    return;
  }
  // WARNING: this seems to be called twice in a row when selected
  component.addEventListener("autocomplete.change", function (evt: any) {
    const mapElement = document.getElementById(mapId);
    if (!mapElement) {
      return;
    }
    const event = evt as CustomEvent;
    const detail = event.detail;
    if (!detail) {
      return;
    }
    const address = null; // Always request reverse geocode for validity check.
    const latLngStr = detail.value;
    const latLng = latLngStr.split(",");
    const latitude = parseFloat(latLng[0]);
    const longitude = parseFloat(latLng[1]);
    mapElement.dispatchEvent(
      new CustomEvent("map:change", {
        detail: {
          latitude,
          longitude,
          address,
        },
      })
    );
  });
}

type Select2FactoryFuction = (root: unknown, jq: JQueryStatic) => JQueryStatic;
const $Select2 = (Select2Factory as Select2FactoryFuction)(null, jQuery);

function setupAllInteractions() {
  $Select2("select.enable-select2")
    .select2({ width: "100%" })
    .on("change", (event) => {
      const target = event.target as HTMLSelectElement;
      if (target.getAttribute("data-submit-on-change") == "true") {
        target.form?.requestSubmit();
      }
    });
  document.querySelectorAll("select").forEach((element) => {
    element.addEventListener("change", (event) => {
      const target = event.target as HTMLSelectElement;
      if (target.getAttribute("data-submit-on-change") == "true") {
        target.form?.requestSubmit();
      }
    });
  });

  const autocompleteComponents = document.querySelectorAll<HTMLDivElement>(
    "div[data-controller=autocomplete]"
  );
  for (const autocompleteComponent of autocompleteComponents) {
    setupAutocompleteAffectsMapListener(autocompleteComponent);
    setupAutocompleteInputListener(autocompleteComponent);
  }
}
document.addEventListener("DOMContentLoaded", () => {
  setupAllInteractions();
});
document.addEventListener("turbo:load", () => {
  setupAllInteractions();
});
