import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export class LinkableNode extends Controller {
  static values = { url: String };
  declare readonly urlValue: string | undefined;

  connect() {
    this.element.addEventListener("click", this.listener);
  }

  listener = () => {
    if (this.urlValue) {
      Turbo.visit(this.urlValue);
    }
  };

  disconnect(): void {
    this.element.removeEventListener("click", this.listener);
  }
}
