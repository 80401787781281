import { Controller } from "@hotwired/stimulus";

export class ContentPoller extends Controller {
  static values = { url: String, pollTimer: Number };
  declare readonly urlValue: string | undefined;
  declare readonly pollTimerValue: number | undefined;

  private timerId: number | null = null;

  setupTimer(interval: number, url: string) {
    if (interval > 0) {
      this.timerId = setTimeout(async () => {
        const response = await fetch(url);
        if (response.ok) {
          const pollHeader = response.headers.get("X-CONTENT-POLL");
          if (pollHeader) {
            const newPoll = parseInt(pollHeader) * 1_000;
            if (newPoll > 0) {
              interval = newPoll;
            }
          }
          this.element.innerHTML = await response.text();
          this.element.dispatchEvent(new CustomEvent("content-poller:update"));
          this.setupTimer(interval, url);
        }
      }, interval);
    }
  }

  connect() {
    if (this.urlValue && this.pollTimerValue) {
      const interval = Math.floor(this.pollTimerValue * 1_000);
      this.setupTimer(interval, this.urlValue);
    }
  }

  disconnect() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }
}
