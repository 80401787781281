import { ActionEvent, Controller } from "@hotwired/stimulus";
import { isElement } from "underscore";

export class Toggle extends Controller {
  static targets = ["toggleable"];
  static values = { open: Boolean };

  private toggleClass: string | undefined;
  private initialState: boolean | undefined;
  declare openValue: boolean;
  declare readonly toggleableTarget: HTMLElement;

  connect() {
    this.toggleClass = this.data.get("class") ?? "hidden";

    this.initialState = this.toggleableTarget.classList.contains(
      this.toggleClass
    );
    document.documentElement.addEventListener(
      "turbo:before-visit",
      this.visitListener
    );
    this.openValue = this.initialState;
  }

  disconnect(): void {
    if (
      typeof this.initialState !== "undefined" &&
      this.openValue !== this.initialState
    ) {
      this.openValue = this.initialState;
    }
    document.documentElement.removeEventListener(
      "turbo:before-visit",
      this.visitListener
    );
  }

  toggle(event: ActionEvent) {
    if (!isElement(event.target)) {
      return;
    }
    if (event.target.getAttribute("type") !== "checkbox") {
      event.preventDefault();
    }

    this.openValue = !this.openValue;
  }

  openValueChanged(newOpenValue: boolean) {
    const classValue = this.toggleClass;
    if (!classValue) {
      return;
    }
    if (newOpenValue) {
      this.toggleableTarget.classList.add(classValue);
    } else {
      this.toggleableTarget.classList.remove(classValue);
    }
  }

  private visitListener = () => {
    if (typeof this.initialState !== "undefined") {
      this.openValue = this.initialState;
    }
  };
}
