/*
 *
 * This file is included on every page, so *only* include resources that will be used on *every*
 * resource. Otherwise use the resource specific Javascript entry points to include the logic you need.
 *
 */
import "@hotwired/turbo-rails";
import "@rails/ujs";
import { Cloudinary, Configuration } from "cloudinary-core";
import "./controllers";
import "./ticket_submissions";
import { updateTimeTags, updateTimestampSpans } from "./util/time";

const updateResponsiveImages = () => {
  const clConfig = new Configuration();
  clConfig.fromDocument();
  const cl = Cloudinary.new(clConfig);
  cl.responsive();
};

const updateAllResponsiveNodes = () => {
  updateResponsiveImages();
  const nodes = document.querySelectorAll(
    "*[data-controller='content-poller']"
  );
  for (const node of nodes) {
    node.addEventListener("content-poller:update", () => {
      updateResponsiveImages();
      updateTimeTags();
      updateTimestampSpans();
    });
  }
};
declare global {
  interface Window {
    copyElementTextToClipboard?: (id: string) => void;
    Rails?: {
      start: () => void;
    };
    _rails_loaded?: boolean;
  }
}

window.copyElementTextToClipboard = (id: string) => {
  const el = document.getElementById(id);
  if (el) {
    const value = String(el.textContent).trim();
    if (value.length > 0) {
      navigator.clipboard.writeText(value);
    }
  }
};

document.addEventListener("DOMContentLoaded", () => {
  updateAllResponsiveNodes();
  updateTimeTags();
  updateTimestampSpans();
});

document.addEventListener("turbo:load", () => {
  updateAllResponsiveNodes();
  updateTimeTags();
  updateTimestampSpans();
});

document.addEventListener("turbo:before-visit", () => {
  document.querySelectorAll<HTMLInputElement>("input#search").forEach((n) => {
    n.value = "";
  });
});

window.addEventListener("load", () => {
  try {
    const tzValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (typeof tzValue === "string" && tzValue.length > 0) {
      document.cookie = `timezone=${tzValue}; max-age=31536000; Path=/; SameSite=None; Secure;`;
    }
  } catch (err) {
    console.error("Failed to capture timezone", err);
  }
  if (!window._rails_loaded) {
    window.Rails?.start();
  }
});
