import { Controller } from "@hotwired/stimulus";

const ENHANCING_ATTRIBUTE = "_enhancing";
const ENHANCING_VALUE = "1";
export class Enhancer extends Controller {
  static values = {
    code: String,
    ticketTypeCode: String,
    path: String,
  };

  static targets = ["button"];

  declare codeValue: string | undefined | null;
  declare pathValue: string | undefined | null;

  declare readonly buttonTarget: HTMLInputElement;
  declare readonly hasButtonTarget: boolean;

  enhanceCodeValue() {
    const path = this.pathValue;
    const code = this.codeValue;
    if (typeof path === "string" && typeof code == "string") {
      const formParams = new FormData();
      formParams.set("code", code);
      document.querySelectorAll("input").forEach((input) => {
        if (input.name.startsWith("submission[details][")) {
          const detailName = input.name.replace(
            /submission\[details\]\[([^\]]+)\](.*)/,
            "details[$1]$2"
          );
          formParams.set(detailName, input.value);
        }
      });

      const token =
        document.getElementsByName("csrf-token")[0].getAttribute("content") ??
        "";
      fetch(path, {
        method: "PATCH",
        body: formParams,
        headers: { "X-CSRF-Token": token },
      }).then((response) => {
        if (response.status === 201) {
          response.json().then((altered: Record<string, unknown>) => {
            for (const key in altered) {
              const value = altered[key];
              if (
                typeof value === "string" ||
                typeof value === "number" ||
                typeof value === "boolean"
              ) {
                const field = this.fieldForCode(key);
                if (field !== null) {
                  field.dataset[ENHANCING_ATTRIBUTE] = ENHANCING_VALUE;
                  try {
                    field.value = String(value);
                  } finally {
                    field.dataset[ENHANCING_ATTRIBUTE] = undefined;
                  }
                }
              }
            }
            if (this.hasButtonTarget) {
              this.buttonTarget.form?.requestSubmit();
            } else {
              this.element.closest("form")?.requestSubmit();
            }
          });
        }
      });
    }
  }

  fieldForCode(code: string): HTMLInputElement | null {
    const prefix = `submission[details][${code}]`;
    return document.querySelector<HTMLInputElement>(`input[name="${prefix}"]`);
  }

  connect() {
    const code = this.codeValue;
    if (code) {
      const field = this.fieldForCode(code);
      if (field) {
        field.addEventListener("change", (_event) => {
          if (!field.dataset[ENHANCING_ATTRIBUTE]) {
            this.enhanceCodeValue();
          }
        });
      }
    }
    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener("click", (event) => {
        this.enhanceCodeValue();
        event.preventDefault();
      });
    }
  }
}
