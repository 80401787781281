import Quagga, { QuaggaJSCodeReader } from "@ericblade/quagga2";
import QrCodeReader from "@ericblade/quagga2-reader-qr/src/index";
import { Controller } from "@hotwired/stimulus";

Quagga.registerReader("qrcode_reader", QrCodeReader);

export class BarcodeScanner extends Controller {
  static values = {
    formats: String,
    code: String,
  };

  declare formatsValue: string | undefined | null;
  declare codeValue: string | undefined | null;

  static targets = ["button", "viewport"];

  declare readonly buttonTarget: HTMLInputElement;
  declare readonly hasButtonTarget: boolean;
  declare readonly viewportTarget: HTMLDivElement;
  declare readonly hasViewportTarget: boolean;

  fieldForCode(code: string): HTMLInputElement | null {
    const prefix = `submission[details][${code}]`;
    return document.querySelector<HTMLInputElement>(`input[name="${prefix}"]`);
  }

  clickListener = () => {
    Quagga.stop();
    this.viewportTarget.removeEventListener("click", this.clickListener);
    this.viewportTarget.innerHTML = "";
  };

  connect() {
    this.buttonTarget.addEventListener("click", (event) => {
      this.viewportTarget.classList.add("active");
      const usedFormats = String(this.formatsValue)
        .split(",")
        .map((str) => `${str.trim()}_reader` as QuaggaJSCodeReader);
      Quagga.init(
        {
          inputStream: {
            name: "Live",
            type: "LiveStream",
            target: this.viewportTarget,
          },
          decoder: {
            readers: usedFormats,
          },
          locate: true,
        },
        (err) => {
          if (err) {
            console.error("Error initializing camera", err);
          } else {
            Quagga.start();
            this.viewportTarget.addEventListener("click", this.clickListener);
          }
        }
      );
      Quagga.onDetected((result) => {
        const code = this.codeValue;
        if (code) {
          const field = this.fieldForCode(code);
          if (field && result.codeResult.code) {
            field.value = result.codeResult.code;
            field.dispatchEvent(new Event("change"));
          }
        }
        console.info("barcode detected", result);
        Quagga.stop();
        this.viewportTarget.innerHTML = "";
        this.viewportTarget.removeEventListener("click", this.clickListener);
        this.viewportTarget.classList.remove("active");
      });
      event.preventDefault();
    });
  }
}
